.loader-ring-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  color: black;
  font-weight: 700;
  font-size: larger;
}

.css-k2olzo {
  line-height: 100vh;
  vertical-align: text-top;
}
